<template>
  <BaseWrapper>
    <template #content>
      <BaseGeometry
        :geometry-type="geometryType"
        :value="value"
        :styles="styles"
        :disabled="disabled"
        :height="height"
        :withAnimate="withAnimate"
      />
    </template>
  </BaseWrapper>
</template>

<script>
import BaseWrapper from '@/components/base/BaseWrapper'
import BaseGeometry from '@/components/base/BaseGeometry'

export default {
  name: 'PassportObjectMap',
  components: { BaseWrapper, BaseGeometry },
  props: {
    value: Object,
    geometryType: String,
    height: {
      type: String
    },
    styles: Object,
    disabled: { Boolean, default: false }, //пропс передается из PassportObjectDetail, идет проверка роли "auditor"
    withAnimate: { Boolean, default: true }
  }
}
</script>